@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');

@font-face {
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/Quicksand.eot'); /* IE9 Compat Modes */
  src: local('Quicksand'), local('Quicksand'),
       url('./assets/fonts/Quicksand.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/Quicksand.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/Quicksand.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/Quicksand.svg') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: "CircularXX-Book";
  src: local('CircularXX-Book'), local('CircularXX-Book'),
  url("./assets/fonts/CircularXXWeb-Book.woff") format("woff");
}

@font-face {
  font-family: "CircularXX-Book";
  src: url("./assets/fonts/CircularXXWeb-Book.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXX-Bold";
  src: url("./assets/fonts/CircularXXWeb-Bold.woff") format("woff");
}

@font-face {
  font-family: "CircularXX-Bold";
  src: url("./assets/fonts/CircularXXWeb-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXX-Black";
  src: url("./assets/fonts/CircularXXWeb-Black.woff") format("woff");
}

@font-face {
  font-family: "CircularXX-Black";
  src: url("./assets/fonts/CircularXXWeb-Black.woff2") format("woff2");
}


@font-face {
  font-family: "Aptos-Light";
  src: local('Aptos-Light'), local('Aptos-Light'),
  url("./assets/fonts/Aptos-Light.woff") format("woff");
}

@font-face {
  font-family: "Aptos-Light";
  src: url("./assets/fonts/Aptos-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Aptos";
  src: url("./assets/fonts/Aptos.woff") format("woff");
}

@font-face {
  font-family: "Aptos";
  src: url("./assets/fonts/Aptos.woff2") format("woff2");
}

@font-face {
  font-family: "Aptos-Bold";
  src: url("./assets/fonts/Aptos-Bold.woff") format("woff");
}

@font-face {
  font-family: "Aptos-Italic";
  src: url("./assets/fonts/Aptos-Bold.woff2") format("woff2");
}

body {
  margin: 0;
  padding: 0;
}

*{
  transition: font-size, margin 0.3s ease;
}